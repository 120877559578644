export const combineInt8Array = (a: any, b: any) => {
  const c = new Int8Array(a.length + b.length);
  c.set(a);
  c.set(b, a.length);
  return c;
};

export const combineFloat32Array = (a: any, b: any) => {
  var c = new Float32Array(a.length + b.length);
  c.set(a);
  c.set(b, a.length);
  return c;
};

export const byteToInt32 = (_byte: any, _offset: number) => {
  return (
    (_byte[_offset] & 255) +
    ((_byte[_offset + 1] & 255) << 8) +
    ((_byte[_offset + 2] & 255) << 16) +
    ((_byte[_offset + 3] & 255) << 24)
  );
  //return _byte[_offset] + _byte[_offset + 1] * 256 + _byte[_offset + 2] * 256 * 256 + _byte[_offset + 3] * 256 * 256 * 256;
};

export const dataURLtoBlob = (dataurl: any) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const blobToDataURL = (blob: Blob, callback: any) => {
  const a = new FileReader();
  a.onload = (e: any) => {
    callback(e.target.result);
  };
  a.readAsDataURL(blob);
};

export const base64toBlob = (
  b64Data: any,
  dataCode: number,
  dataId: number,
  contentType = ""
) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [
    new Uint8Array(int32ToBytes(dataCode)).reverse(),
    new Uint8Array(int32ToBytes(dataId)).reverse(),
    new Uint8Array(int32ToBytes(byteCharacters.length, 4)).reverse(),
    new Uint8Array(int32ToBytes(0, 4)).reverse(),
  ];
  const contentArrays = [];

  for (let i = 0; i < byteCharacters.length; i++) {
    contentArrays.push(byteCharacters.charCodeAt(i));
  }

  byteArrays.push(new Uint8Array(contentArrays));
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const blobTobase64 = (blob: any) => {
  var reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onloadend = function () {
    var base64data = reader.result;
  };
};

export const int32ToBytes = (
  num: number,
  byteNum: number = 4,
  byteOffset: number = 0
) => {
  const arr = new ArrayBuffer(byteNum); // an Int32 takes 4 bytes
  const view = new DataView(arr);
  view.setUint32(byteOffset, num, false); // byteOffset = 0; litteEndian = false
  return arr;
};

export const bytesToInt32 = (arr: Uint8Array, byteOffset: number = 0) => {
  const view = new DataView(arr.buffer);
  return view.getUint32(byteOffset); // byteOffset = 0; litteEndian = false
};

export const convertDataToObject = (data: string, categoryMarker: string = "X") => {
  const lines = data.trim().split('\n');
  const headers = lines[0].split(',').map(header => header.trim());
  const result: any = {};

  for (let i = 1; i < lines.length; i++) {
      const values = lines[i].split(',').map(value => value.trim());
      const task = values[0].replace(/"/g, '');
      result[task] = [];

      for (let j = 1; j < values.length; j++) {
          if (values[j] === categoryMarker) {
              result[task].push(headers[j]);
          }
      }
  }

  return result;
};
