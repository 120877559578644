import { ATtaskBenefits } from "../settings/app";

export const getActiveFacilityPayload = (subType: any, uid: string) => {
  const payload: any = {};

  if (['hospitals'].indexOf(subType) >= 0) {
    payload.activeClinic = null;
    payload.activeTherapist = null;
    payload.activePatient = null;
    payload.activeSession = null;
    if (!uid)
      payload.activeHospital = null;
  } else if (['clinics'].indexOf(subType) >= 0) {
    payload.activeTherapist = null;
    payload.activePatient = null;
    payload.activeSession = null;
    if (!uid)
      payload.activeClinic = null;
  } else if (['therapists'].indexOf(subType) >= 0) {
    payload.activePatient = null;
    payload.activeSession = null;
    if (!uid)
      payload.activeTherapist = null;
  } else if (['patients'].indexOf(subType) >= 0) {
    payload.activeSession = null;
    if (!uid)
      payload.activePatient = null;
  }

  return payload;
}

export const getInternalTaskName = (taskName: string) => {
    if (ATtaskBenefits.hasOwnProperty(taskName)) 
    {
      return taskName;
    }
    // exceptions for tasks with different names
    switch (taskName) {
      case "Dance Party":
        return "Grover's Dance Party";
      case "Snack Land":
        return "Snackland";
      case "Jumping Jacks":
        return "The Count's Jumping Jacks";
      case "Shoulder Abduction SS":
        return "Elmo's Shoulder Abduction";
      case "Yoga Sesame":
        return "Yoga with Bert";
      case "Deep Breathing":
        return "Abby's Deep Breathing";
      case "Talk to Peridot":
        return "Peridot Speech Trainer";
      case "Memory Sesame":
        return "Sesame Memory Cards";
      case "Peridot Run in Place":
        return "Peridot Run In Place";
      case "Casual Clothing, Female":
        return "Casual Clothing, Female";
    }
    console.log("Task name not found: " + taskName);
    return taskName;
  }