import { convertDataToObject } from "../utils/functions";

export const APIURL_PRODUCTION = 'https://api2.augmenttherapy.com';
export const APIURL_TESTING = 'https://at-admin-api-test.azurewebsites.net'; 
export var RUN_MODE = "production"; // "production" or "testing"

export const AdminVersion = "1.4.0";

export const ReportDaysPrevious: number = 45; //45;
export const chartType = "line"; //"area"; //"line"; //"column";
export const chartStack:boolean = false; //false; //true;
export const AppStoreURL:string = "https://apps.apple.com/us/app/arwell-pro/id6463829198";
export const ATnewsURL:string = "https://augmenttherapy.com/support/";
export const ATtherapistRemoteURL:string = "https://admin1.augmenttherapy.com/remote/index.html";
export const ATsupportSite:string = "https://augmenttherapy.com/providers/";
export const ATtelehealthinstall:string = "https://admin1.augmenttherapy.com//telehealth/atinstaller.msi";
export const ATuserguide:string = "https://augmenttherapy.com/wp-content/uploads/2023/04/Augment-Therapy-User-Guide-3.29.23.pdf";
export const ATexerciseGuide:string = "https://augmenttherapy.com/wp-content/uploads/2023/04/AT-Exercise-Reference-Guide-9.1.pdf";
export const ATsetupAndUseLink:string = "https://augmenttherapy.com/support/";
export const TimeZoneOptions: any = [
    {label:"Hawaiian (HST)",value:"Hawaiian Standard Time"},
    {label:"Pacific (PST)",value:"Pacific Standard Time"},
    {label:"Mountain (MST)",value:"Mountain Standard Time"},
    {label:"Central (CST)",value:"Central Standard Time"},
    {label:"Eastern (EST)",value:"Eastern Standard Time"},
    {label:"GMT Standard (GMT)",value:"GMT Standard Time"}
];

export const ATadlTasks = ["Bowl of Cereal","Brush Teeth","Casual Clothing, Female","Daily Clothing, Female","Formal Clothing, Female","Fried Eggs","Hamburgers","Laundry","PB&J Sandwich"];

export const ATtaskDetailStats = {
    columns: [
      { field: 'startTime', title: 'Start Time', shortTitle: 'Started' },
      { field: 'totalRepsDurationL', title: 'Repetitions Duration (Left)', shortTitle: 'Reps Duration (L)' },
      { field: 'totalRepsDurationR', title: 'Repetitions Duration (Right)', shortTitle: 'Reps Duration (R)' },
      { field: 'totalRepsDuration', title: 'Repetitions Duration (Total)', shortTitle: 'Reps Duration (Total)' },
      { field: 'mpm', title: 'Estimated Movements Per Minute', shortTitle: 'Est. movements per minute' },
      { field: 'maxRepDurationL', title: 'Max Repetitions Duration (Left)', shortTitle: 'Max Reps Duration (L)' },
      { field: 'maxRepDurationR', title: 'Max Repetitions Duration (Right)', shortTitle: 'Max Reps Duration (R)'},
      { field: 'maxRepDuration', title: 'Max Repetitions Duration (Total)', shortTitle: 'Max Reps Duration (Total)' },
      { field: 'restTime', title: 'Rest Time', shortTitle: 'Rest' },
      { field: 'distance', title: 'Distance (Inches)', shortTitle: 'Distance (In.)' },
      { field: 'height', title: 'Height (Inches)', shortTitle: 'Height (In.)' },
      { field: 'taskSteps', title: 'Steps', shortTitle: 'Steps'},
      { field: 'avgAngleL', title: 'Average Angle (Left)', shortTitle: 'Average Angle (L)' },
      { field: 'avgAngleR', title: 'Average Angle (Right)', shortTitle: 'Average Angle (R)' },
      { field: 'maxAngleL', title: 'Max Angle (Left)', shortTitle: 'Max Angle (L)' },
      { field: 'maxAngleR', title: 'Max Angle (Right)', shortTitle: 'Max Angle (R)' },
      { field: 'secondsRequested', title: 'Time Requested', shortTitle: 'Time Requested' },
      { field: 'repetitions', title: 'Total Repetitions', shortTitle: 'Total Reps' },
    ]
  };

  export const TasksThatUseDuration = ["Arm Circles", "Candy Town", "Goal Kick", "Grover's Dance Party","March in Place", "Music Mayhem", "Nature Hike", "Obstacle Course", "Run in Place", "Sit and Reach", "Skiing", "Snackland", "Surfing", "The Count's Jumping Jacks"];

  export const TaskBenefitsRawData = `
  EXERCISE,STRENGTH ,BILATERAL COORDINATION,RANGE OF MOTION,DYNAMIC BALANCE,STATIC BALANCE,CARDIOVASCULAR ENDURANCE,POSTURAL STABILITY,BODY AWARENESS,MOTOR CONTROL,MOTOR PLANNING,STANDING ENDURANCE,WEIGHT SHIFT,EXECUTIVE FUNCTION,MIDLINE CROSSING,LATERAL MOVEMENT,VISUAL MOTOR ,VISUAL DISCRIMINATION,COGNITIVE SKILLS
Abby's Deep Breathing,,X,X,,,,X,X,X,,,,X,X,,X,,X
Air Writing,,X,X,X,X,,X,X,X,X,X,X,X,X,,,,X
Arm Circles,X,X,X,X,,X,X,X,,,X,,,,,,,
Avatar Guitar,,X,,X,,X,,,,,X,,,,,,,
Ball Pit,,,X,X,X,X,X,X,,,X,X,,,,,,
Bowl of Cereal,,,X,X,X,,X,X,X,X,X,,X,X,,,,X
Bowling,,X,,X,,,,,X,X,X,,X,,,,,
Bridges,X,,X,,X,,X,,X,,,,,,,,,
Brush Teeth,,,X,X,X,,X,X,X,X,X,,X,X,,,,X
Candy Town,,X,,X,,X,,X,,X,,,X,,,,,X
Castleton,,X,,X,,X,,X,,X,,,X,,,,,X
"Casual Clothing, Female",,,X,X,X,,X,X,X,X,X,,X,X,,,,X
Child's Pose,X,,X,,,,X,X,X,,,,X,,,,,
Cobra,X,,X,,,,X,X,X,,,,X,,,,,
Color Ball,,X,X,X,,X,X,X,X,X,X,X,X,X,X,X,X,X
Cookie Ball,,X,,X,,X,,X,,X,X,,X,,,,,
"Daily Clothing, Female",,,X,X,X,,X,X,X,X,X,,X,X,,,,X
"Daily Clothing, Male",,,X,X,X,,X,X,X,X,X,,X,X,,,,X
Downward Dog,X,,X,,X,,X,X,X,,,,X,,,,,
Elmo's Air Writing,,X,X,X,X,,X,X,X,X,X,X,X,X,,,,X
Elmo's Shoulder Abduction,,,X,X,,,X,X,,,X,,,,,,,X
Figure 4 Stretch,,,X,,,,,X,,X,,,,X,,,,
Flying,,X,,X,,X,X,X,,X,,,X,,,,,X
"Formal Clothing, Female",,,X,X,X,,X,X,X,X,X,,X,X,,,,X
Fried Eggs,,,X,X,X,,X,X,X,X,X,,X,X,,,,X
Grover's Dance Party,,,,X,,X,,X,,,,,,,X,,,
Hamburgers,,,X,X,X,,X,X,X,X,X,,X,X,,,,X
Hip Abduction,X,,,X,,,X,X,,,X,X,,,,,,
Hip Extension,X,,,X,,,X,X,,,X,X,,,,,,
Hop,X,X,,X,,,,X,X,X,,,,,,,,
IWall,,X,,X,,X,,X,,X,X,X,X,X,X,,,X
Jump in Place,X,X,,X,,,,X,,X,,,,,,,,
Goal Kick,,X,,X,,,X,X,X,X,X,X,X,,,,,
Laundry,,,X,X,X,,X,X,X,X,X,,X,X,,,,X
Lunges,,,,,,,,,,,,,,,,,,
March in Place,X,X,,X,,X,X,X,,,,X,,,,,,
Memory Cards,,,X,X,X,,X,X,X,X,X,,X,X,,,,X
Mini Squats,X,,,X,,X,X,X,X,X,X,,,,,,,
Music Mayhem,,X,X,X,,X,X,X,,X,X,X,,X,X,,X,
Nature Hike,,X,,X,,X,,X,,X,,,X,,,,,X
Obstacle Course,,X,,X,,X,,X,X,X,X,X,X,,X,,,X
PBJ Sandwich,,,X,X,X,,X,X,X,X,X,,X,X,,,,X
Pickleball,,X,,X,,,,X,,X,X,X,X,,,,,
Plank,X,,,,,,X,X,,X,,,,,,,,
Puddle Jumper,X,X,,X,,,,X,X,X,,,,,,,,
Punch Ball,,X,,X,,X,,X,,X,X,,X,,,,,
Rocket Launcher,X,X,,X,,X,X,X,X,X,X,X,,,,,,
Run in Place,X,X,,X,,X,,X,,X,,,,,,,,
Seated Long Arc Quad,X,X,X,,X,X,X,X,X,,,,,,,,,
Sesame Memory Cards,,,X,X,X,,X,X,X,X,X,,X,X,,,,X
Shoulder Abduction,,,X,X,,,X,X,,,X,,,,,,,
Shoulder Flexion,,,X,,,,X,X,,,X,,,,,,,
Single Limb Stance,X,,,,X,X,X,X,X,,X,X,,,,,,
Sit and Reach,,X,X,X,X,,X,X,,X,X,X,,X,,,X,
Skiing,,X,,X,X,,X,X,X,X,X,X,X,,,,,
Snackland,,X,,X,,X,,X,,X,,,X,,,,,X
Snow Globe,,,,X,X,X,X,X,,,X,X,,,,,,
Standing Tolerance,X,,,,X,X,X,X,X,,X,,,,,,,
Star Catcher,,X,,X,,X,X,X,,X,,,X,,,,,X
Surfing,,X,,X,X,,X,X,X,X,X,X,X,,,,X,
The Count's Jumping Jacks,,X,,X,,X,,X,X,X,,,,,,,,
Town,,X,,X,,X,,X,,X,,,X,,,,,X
Warrior Pose,X,X,X,X,X,,X,X,X,X,X,X,,,,,,
Yoga with Bert,X,X,X,X,X,,X,X,X,X,,,,,,,,
Yoga,X,X,X,X,X,,X,X,X,X,,,,,,,,
Hide and Seek,,,,X,,X,,X,X,X,X,X,X,,,X,X,X
Peridot Hop,X,X,,X,,,,X,X,X,,,,,,,,
Peridot Mini Squats,X,,,X,,X,X,X,X,X,X,,,,,,,
Peridot Run In Place,X,X,,X,,X,,X,,X,,,,,,,,
Peridot Speech Trainer,,,,,,,,,,,,,,,,,,X
`;

export const ATtaskBenefits = convertDataToObject(TaskBenefitsRawData);

